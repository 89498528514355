import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '../loading.js';
import './tutorial_battleship.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faInfo, faExclamation, faFaceGrinWink, faSkull, faThumbsUp, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import parse from 'html-react-parser'; 

const Tutorial = forwardRef(({diffLevel,battleship_game},ref) => {
  // Check video type
  const videoType = useRef(localStorage.getItem('videoType') || 'mp4');
  const [instructions, setInstructions] = useState([]);
  const [videos, setVideos] = useState({});

  useEffect(() => {
    async function loadVideos() {
      try {
        const [video1, video2, video3, video4] = await Promise.all([
          import(`../assets/tutorial1.${videoType.current}`),
          import(`../assets/tutorial2.${videoType.current}`),
          import(`../assets/tutorial3.${videoType.current}`),
          import(`../assets/tutorial4.${videoType.current}`)
        ]);

        setVideos({
          video1: video1.default,
          video2: video2.default,
          video3: video3.default,
          video4: video4.default
        });
      } catch (error) {
        console.error("Error loading videos:", error);
      }
    }

    loadVideos();
  }, []);

  // Redirect to dock if localStorage is empty
  const navigate = useNavigate();
  useEffect(() => {
    if(localStorage.getItem('videoType') === null){
      navigate('/dock');
    }
  },[navigate]);

  useEffect(() => {
    if (Object.keys(videos).length === 0) return;
    const baseInstructions = [
      {
        id: 1,
        video: {
          src: videos.video1,
          type: 'video/'+videoType.current
        },
        content: {
          title: 'Setting your board',
          icon: faInfo,
          listItems: [
            'Click <i>Ship Shuffle</i> to randomize ships.',
            'Press down and drag a ship.',
            'Right-click to rotate the ship.'
          ]
        }
      },
      {
        id: 2,
        video: {
          src: videos.video2,
          type: 'video/'+videoType.current
        },
        content: {
          title: 'Your Objective',
          icon: faInfo,
          type: 'info',
          listItems: [
            'Sink all enemy ships',
            'Hide your ships strategically',
            'Choose your attacks wisely'
          ]
        }
      },
      {
        id: 3,
        video: {
          src: videos.video3,
          type: 'video/'+videoType.current
        },
        content: {
          title: 'Enemy Can Steal Your Turn!',
          icon: faExclamation,
          type: 'warning',
          listItems: [
            'If your ship is hit, the enemy gets another chance.',
            'With an extra turn, the enemy can hit again.',
            "The enemy can hit consecutively if they don't miss."
          ]
        }
      },
      {
        id: 4,
        video: {
          src: videos.video4,
          type: 'video/'+videoType.current
        },
        content: {
          title: 'But You Can Steal A Turn...',
          icon: faThumbsUp,
          type: 'success',
          listItems: [
            'If you hit an enemy ship, you get another chance.',
            'To win the extra turn, fingerspell.',
            "Fingerspelling has a time limit."
          ]
        }
      }
    ];

    let updatedInstructions = [...baseInstructions];

    if(diffLevel === 'easy'){
      updatedInstructions[2] = {
        id: 3,
        video: {
          src: videos.video2,
          type: `video/${videoType.current}`
        },
        content: {
          title: "Enemy Can't Steal Your Turn!",
          icon: faFaceGrinWink,
          type: 'success',
          listItems: [
            'Because you are in the easy mode.',
            'The enemy can only hit once per turn.',
            "No extra turns for consecutive hits by the enemy."
          ]
        }
      };
    } else if(diffLevel === 'hard'){
      updatedInstructions[3] = {
        id: 4,
        video: {
          src: videos.video3,
          type: `video/${videoType.current}`
        },
        content: {
          title: "You Can't Steal A Turn!",
          icon: faSkull,
          type: 'warning',
          listItems: [
            'Because you are in the hard mode.',
            'You can only hit once per turn.',
            "No extra turns for consecutive hits by you."
          ]
        }
      };
    }

    setInstructions(updatedInstructions);
  },[diffLevel,videos]);

  const [currentIndex,setCurrentIndex] = useState(0);
  const [isClosed, setClose] = useState(battleship_game ? (localStorage.getItem('wentthroughTutorial') === 'true') || false : true);

  const closeMenu = () => {
    localStorage.setItem('wentthroughTutorial',true);
    setClose(prev => prev ? false : true);
  };

  const clickLeftArrow = () => {
    const num = instructions.length;
    setCurrentIndex(prev => prev === 0 ? num-1 : prev - 1);
  };

  const clickRightArrow = () => {
    const num = instructions.length;
    setCurrentIndex(prev => prev === num-1 ? 0 : prev + 1);
  };

  const clickBullet = (e) => {
    setCurrentIndex(e);
  }

  const openTutorial = () => {
    setClose(false);
  }

  return(
    <div className = 'tutorial_game' disabled = {isClosed}>
      <button style = {{display: 'none'}} ref={ref?.childButtonRef} onClick = {openTutorial}/>
      <button onClick = {clickLeftArrow} id='left-arrow'>
        <FontAwesomeIcon icon={faAngleLeft} />
      </button>
      <button onClick = {clickRightArrow} id ='right-arrow'>
        <FontAwesomeIcon icon={faAngleRight} />
      </button>
      <div className = 'tutorial-container'>
        <button onClick = {closeMenu} className='closeMenu'>
          <FontAwesomeIcon className='sub-closeMenu' icon={faXmark} />
        </button>
        {instructions.map((tutorial,index)=> {
          return(
            <div key={index} className = 'tutorial'>
              {Object.keys(videos).length === 0 || instructions.length === 0 ? (<Loading/>) :
                (<video controls muted autoPlay loop playsInline>
                  <source src={tutorial.video?.src} type={tutorial.video?.type}/>
                </video>)
              }
              <div className = 'tutorial-text'>
                <div>
                  <h3>
                    <div className = { tutorial.content.type === 'warning' ? 'warn-icon' : tutorial.content.type === 'success' ? 'success-icon' : 'info-icon'}>
                      <FontAwesomeIcon icon={tutorial.content.icon} />
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{tutorial.content.title}
                  </h3>
                  <ul>
                    {tutorial.content.listItems.map((item,itemIndex) => {
                      return <li key={itemIndex}>{parse(item)}</li>
                    })}
                  </ul>
                </div>
                <div>
                </div>
              </div>
            </div>
          )
        })[currentIndex]}
              <div className = 'button-bullet-wrap'>
        {instructions.map((item,index) => {
          return(
            <button 
              className = 'page-slider-buttons' 
              key={index} 
              onClick = {() => clickBullet(index)}
              disabled = {item.id === currentIndex+1 ? true : false}
            >
              <div/>
            </button>
          )
        })}
      </div>
      </div>
    </div>
  )
});

export default Tutorial;