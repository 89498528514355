import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBug } from '@fortawesome/free-solid-svg-icons';

//import 'font-awesome/css/font-awesome.min.css';
import './loading.css';

export default function Everything() {
 return (
  <>
    <div className = 'loading-background'>
      <div className="loading-wrapping">
        <svg viewBox="25 25 50 50" className='loading-svg'>
          <circle r="20" cy="50" cx="50"></circle>
        </svg>
      </div>
    </div>
  </>
 );
}

// Handle all errors
export class ErrorBoundary extends Component {
  constructor(props){
    super(props);
    this.state = { hasError: false, error: null };
  };

  static getDerivedStateFromError(error){
    return { hasError: true, error };
  };

  componentDidCatch(error, errorInfo){
    console.error('Error caught by ErrorBoundary: ', error, errorInfo);
  };

  setError = (error) => {
    this.setState({ hasError: true, error });
  }

  // Refresh browser
  refreshBrowser = () => {
    window.location.reload();
  }

  // Four seconds to refresh browser automatically
  startRefreshCountdown = () => {
    this.setState({ isRefreshing: true });
    this.refreshTimeout = setTimeout(() => {
      window.location.reload();
    }, 10000);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.hasError && this.state.hasError) {
      // Start the refresh countdown when an error occurs
      this.startRefreshCountdown();
    }
  }

  // Handle async error
  handlePromiseRejection = (e) => {
    e.preventDefault(); // Prevents the error from being logged to the console
    console.error('Unhandled Promise Rejection:', e.reason);
    this.setState({ 
      hasError: true, 
      error: e.reason 
    });
  };

  componentDidMount() {
    window.addEventListener('unhandledrejection', this.handlePromiseRejection);
  }

  componentWillUnmount() {
    window.removeEventListener('unhandledrejection', this.handlePromiseRejection);
  }

  render() {
    if(this.state.hasError){
      return(
        <div className = 'loading-background'>
          <div className="loading-wrapping">
            <h1>
              <FontAwesomeIcon icon={faBug}/>
            </h1>
            <p>{this.state.error && this.state.error.toString()}</p>
            <button 
              className = 'refresh-button' 
              onClick={this.refreshBrowser}
            >
              Refresh?
            </button>
          </div>
        </div>
      )
    }

    return this.props.children;
  }
}