import React, { useEffect, useMemo } from 'react';
import { useOktaAuth } from "@okta/okta-react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import Loading from '../loading.js';

const SecureRoute = () => {
  const { authState } = useOktaAuth();
  const navigate = useNavigate();

  const isSkip = useMemo(() => {
    const skipLoginDate = localStorage.getItem('skipLoginDate');
    return skipLoginDate && (Date.now() - parseInt(skipLoginDate) <= 30*60*1000);
  }, []);
  
  useEffect(() => {
    if(authState && !authState.isAuthenticated && !isSkip){
      navigate('/login');
    }
  },[authState,isSkip,navigate]);

  if(!authState){
    return <Loading />;
  }

  return authState.isAuthenticated || isSkip ? <Outlet /> : <Navigate to='/login' />
}

export default SecureRoute;