import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../battleship_game/battleship.css';
import '../battleship_game/dock.css';
import { LoginCallback, SignUpCallback, GoogleLoginCallBack, FaceBookLoginCallBack } from './logincallback.js';

const LoginPage = () => {
  const navigate = useNavigate();

  const skipLogin = () => {
    navigate('/dock');
    localStorage.setItem('skipLoginDate',Date.now());
  };

  return(
    <div className = 'white-background'>
        <div className = 'buttons-container'>
          <div className = 'game-start-container'>
            <p style = {{textAlign: 'left', padding: '10px', marginTop: '10px'}}>
              Help train AI for the Deaf community.
            </p>
            <LoginCallback />
            <p style = {{textAlign: 'center', padding: '10px'}}>or</p>
            <GoogleLoginCallBack />
            <FaceBookLoginCallBack />
            <SignUpCallback />
            <button id='skip-to-game' onClick={skipLogin}>
              <div>
                Skip
                <svg fill="var(--gallynavyblue)" viewBox="200 95 184.971 214.971">
                  <g strokeWidth="0"></g>
                  <g strokeLinecap="round" strokeLinejoin="round"></g>
                  <g> 
                    <g> 
                      <g>
                        <path d="M 381.481 184.088 l -83.009 -84.2 c -4.704 -4.752 -12.319 -4.74 -17.011 0 c -4.704 4.74 -4.704 12.439 0 17.179 l 62.558 63.46 H 240 c -6.641 0 -12.03 5.438 -12.03 12.151 c 0 6.713 5.39 12.151 12.03 12.151 h 104 l -62.558 63.46 c -4.704 4.752 -4.704 12.439 0 17.179 c 4.704 4.752 12.319 4.752 17.011 0 l 82.997 -84.2 C 386.113 196.588 386.161 188.756 381.481 184.088 z"/>
                      </g> 
                    </g> 
                  </g>
                </svg>
              </div>
            </button><br/>
          </div>
        </div>
    </div>
  )
};

export default LoginPage